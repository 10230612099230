<script lang="ts">
  import {
    isCartClosed,
    cartItems,
    removeCartItem,
    discountCode,
    discountPercentage,
    type BoostingCartItem
  } from "@stores/cart";
  import Drawer from "flowbite-svelte/Drawer.svelte";
  import CloseButton from "flowbite-svelte/CloseButton.svelte";
  import Popover from "flowbite-svelte/Popover.svelte";
  import Alert from "flowbite-svelte/Alert.svelte";
  import Modal from "flowbite-svelte/Modal.svelte";
  import LoadingOutlined from "~icons/ant-design/loading-outlined";

  import { sineIn } from "svelte/easing";
  import Rocket from "@images/rocket_launch.svg";
  import SkinIncluded from "@images/skin.svg";
  import Stripe from "@images/stripe.svg";
  import Bitcoin from "@images/bitcoin.svg";
  import RadioChecked from "@images/radio-checked.svg";
  import RadioUnchecked from "@images/radio-unchecked.svg";
  import Cart from "~icons/tabler/shopping-cart";
  import Trash from "~icons/tabler/trash";
  import Mail from "~icons/tabler/mail-filled";
  import StripePaymentMethods from "./stripe-payment-methods.svelte";
  import Repeat from "~icons/tabler/repeat";
  import { fly } from "svelte/transition";
  import { loadStripe } from "@stripe/stripe-js";
  import Badge from "@components/ux/badge.svelte";
  import QrCode from "@components/ux/qrcode.svelte";
  import type {
    CreateOrderRequest,
    OrderLineItemsType
  } from "@server/types/order-details";
  import { client, getTRPCErrors } from "@helpers/trpc/trpc-client";
  import { user } from "@stores/user";
  import { onMount } from "svelte";
  import Cookies from "universal-cookie";
  import Swal from "sweetalert2";
  import {
    gameDetails,
    games,
    getBoostingShortDescriptionWithImage
  } from "@frontend/games/all";
  import { typedEntries } from "@frontend/helpers/typed-entries";
  import { hasOwnProperty } from "@frontend/helpers/has-own-property";
  import { Image } from "@components/ux/image";
  import LoginDialog from "@components/login/login-dialog.svelte";

  export let stripe_public_key: string;

  let transitionParams = {
    x: 320,
    duration: 200,
    easing: sineIn
  };
  $: total = Object.values($cartItems).reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  let email = "";
  let isLoadingCheckout = false;
  let checkoutErrors: string[] = [];

  let btcDialogOpen = false;
  let btcAmount = 0;
  let btcAddress = "";
  let btc_timeinterval: NodeJS.Timer | null = null;
  let btcQrcode: string = "";

  let selectedPaymentMethod: "stripe" | "bitcoin" = "stripe";

  let showLoginDialog: boolean = false;

  const startCheckout = async () => {
    const line_items: OrderLineItemsType = Object.values($cartItems);

    // check for auth for buying boosting or any subscription item
    if (
      line_items.some(
        (item) => item.type === "boosting" || item.subscription
      ) &&
      !$user
    ) {
      showLoginDialog = true;
      return;
    }

    isLoadingCheckout = true;

    try {
      if ($user) email = $user.email;

      const orderRequest: CreateOrderRequest = {
        email,
        payment_method: selectedPaymentMethod,
        discount: $discountCode,
        line_items
      };

      const checkoutSession =
        await client.payment.createOrder.mutate(orderRequest);

      if (checkoutSession.payment_method == "stripe") {
        const stripe = await loadStripe(stripe_public_key);

        if (!stripe) {
          isLoadingCheckout = false;
          checkoutErrors = [
            "Failed to initialize Stripe. Please try again later."
          ];
          return;
        }

        if (!checkoutSession.id) {
          checkoutErrors = ["Failed to create an order, please try again."];
          return;
        }

        //remove items from cart
        Object.entries($cartItems).forEach(([id, item]) => removeCartItem(id));

        const result = await stripe.redirectToCheckout({
          sessionId: checkoutSession.id
        });

        if (result.error) {
          checkoutErrors = ["Error redirecting to stripe."];
        }
      } else if (checkoutSession.payment_method == "bitcoin") {
        //close cart
        $isCartClosed = true;

        if (!checkoutSession.total_in_btc || !checkoutSession.address) {
          checkoutErrors = ["Failed to create an order, please try again."];
          return;
        }

        btcAmount = checkoutSession.total_in_btc;
        btcAddress = checkoutSession.address;
        const order_id = checkoutSession.order_id;

        btcQrcode = `bitcoin:${btcAddress}?amount=${btcAmount}`;

        //open dialog
        btcDialogOpen = true;

        if (btc_timeinterval) clearTimeout(btc_timeinterval);

        btc_timeinterval = setInterval(async () => {
          const intent_status =
            await client.payment.orderPaymentStatus.query(order_id);

          if (intent_status.payment_status === "paid") {
            window.location.href = `/payment/success/${order_id}`;
          }
        }, 5000);
      }
    } catch (error) {
      checkoutErrors = getTRPCErrors(error);
    }

    isLoadingCheckout = false;
  };

  onMount(() => {
    //check discount code
    const urlParams = new URLSearchParams(window.location.search);
    let urlDiscountCode = urlParams.get("discount");

    const cookies = new Cookies();
    if (urlDiscountCode) {
      cookies.set("discountCode", urlDiscountCode, {
        path: "/",
        maxAge: 3600 * 24 * 365
      });
    }

    //Add boosting 21% discount to boosting pages
    //If you remove this make sure to edit the Boosting performance max campaign
    if (window?.location.href.includes("boosting")) {
      urlDiscountCode = "21PERCENTBOOST";
    }

    //check if discount code actually works
    if (cookies.get("discountCode") || urlDiscountCode) {
      const discount = cookies.get("discountCode") || urlDiscountCode;
      const getDiscountData = async () => {
        const discountData = await client.payment.discount.query(discount);
        if (!discountData.discount) return;

        //it doesn't work....
        if (discountData.discount <= 0) {
          cookies.remove("discountCode");
        } else {
          discountCode.set(discount);

          discountPercentage.set(discountData.discount / 100);
          await Swal.fire(
            `${discountData.discount}% OFF for a limited time!`,
            `Your coupon code: ${discount} has been applied. You will see discounted prices on the checkout page.`,
            "success"
          );
        }
      };
      //eslint-disable-next-line
      getDiscountData();
    }
  });

  const getAddonValue = (
    cartItem: BoostingCartItem,
    addonName: string
  ): boolean | Record<string, any> | undefined => {
    if (hasOwnProperty(cartItem.data.addons, addonName))
      //@ts-ignore
      return cartItem.data.addons[addonName];

    return false;
  };
</script>

{#if Object.values($cartItems).length && $isCartClosed}
  <button
    transition:fly={{ x: -500, duration: 1000 }}
    id="cart-open"
    class="fixed bottom-0 right-0 z-20 mb-24 mr-5 rounded-full border border-white bg-white p-4 text-black"
    on:click={() => ($isCartClosed = !$isCartClosed)}
    ><Cart class="h-6 w-6" />
    <div
      class="absolute right-0 top-0 inline-flex h-6 w-6 flex-shrink-0 -translate-y-1/3 translate-x-1/3 items-center justify-center rounded-full bg-yellow-300">
      {Object.values($cartItems).length}
    </div>
  </button>

  <Popover class="text-sm" placement="left" triggeredBy="#cart-open"
    >Open Cart</Popover>
{/if}

<Drawer
  placement="right"
  transitionType="fly"
  {transitionParams}
  bind:hidden={$isCartClosed}
  id="sidebar1"
  class="bg-neutral-800 "
  width="max-w-sm flex flex-col">
  <div class="mb-2 flex items-center">
    <h5
      id="drawer-label"
      class="inline-flex items-center text-base font-semibold">
      <Cart class="mr-2 h-6 w-6" />My Cart ({Object.keys($cartItems).length})
    </h5>
    <CloseButton
      on:click={() => ($isCartClosed = true)}
      class="rounded-full bg-neutral-700 dark:text-white" />
  </div>

  <div class="flex flex-1 flex-col gap-2">
    {#if Object.values($cartItems).length}
      <aside class="flex flex-1 flex-col gap-2">
        {#each Object.entries($cartItems) as [id, cartItem] (id)}
          <div
            transition:fly={transitionParams}
            class="grid gap-4 rounded-2xl bg-neutral-900 p-4">
            <div class="flex flex-row items-center gap-4">
              {#if cartItem.type === "account"}
                <img
                  src="/images/{cartItem.data.region}-{cartItem.data
                    .level}.webp"
                  alt="Product"
                  class="h-24" />
              {:else}
                <img
                  src={gameDetails[cartItem.data.game].cartIcon}
                  alt="Product"
                  class="h-24" />
              {/if}
              <div class="flex flex-1 flex-col gap-2">
                {#if cartItem.type === "account"}
                  <p
                    class="max-w-fit rounded-md bg-neutral-800 px-2 py-1 text-xs uppercase">
                    {cartItem.subscription
                      ? "LoL Account Subscription"
                      : "LoL Account"}
                  </p>
                  <p class="max-w-fit text-xs font-medium">
                    Type: <span class=" uppercase text-white"
                      >{cartItem.data.region} level {cartItem.data.level}</span>
                  </p>
                {:else if cartItem.type === "boosting"}
                  <p
                    class="max-w-fit rounded-md bg-neutral-800 px-2 py-1 text-xs uppercase">
                    {cartItem.data.game} boosting
                  </p>
                  <p class="max-w-fit text-xs font-medium">
                    Type: <span class="text-white"
                      >{games?.[cartItem.data.game]?.[cartItem.data.type]
                        ?.title}</span>
                  </p>
                {/if}
              </div>
              <div
                class="flex items-center rounded-full bg-white px-1 py-1 text-lg font-semibold text-black">
                {cartItem.currency}{cartItem.price}
                {#if cartItem.subscription}<span
                    class="text-xs text-neutral-500">/Month</span>
                {/if}
              </div>
            </div>

            {#if cartItem.type === "boosting"}
              {@const shortDescription = getBoostingShortDescriptionWithImage(
                cartItem.data
              )}
              <p
                class="text-md flex flex-row items-center justify-between gap-2 text-sm font-medium text-white">
                {#if shortDescription.from}
                  <div class="flex items-center gap-2 rounded-xl">
                    {#if shortDescription.from.type === "image"}
                      <img
                        src={shortDescription.from.image}
                        alt={shortDescription.from.description}
                        class="h-full w-12" />
                    {:else if shortDescription.from.type === "text"}
                      <span
                        class="inline-flex aspect-square items-center justify-center rounded-full bg-neutral-800 px-3 text-lg text-white"
                        >{shortDescription.from.text}</span>
                    {/if}
                    <div class="text-center">
                      {shortDescription.from.description}
                    </div>
                  </div>
                {/if}

                {#if shortDescription.from && shortDescription.to}
                  <!--Right Arrow SVG-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none">
                    <mask
                      id="mask0_2259_3661"
                      style="mask-type:alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="25"
                      height="24">
                      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2259_3661)">
                      <path
                        d="M12.5 15.6442L16.1442 12L12.5 8.35578L11.4558 9.39998L13.3058 11.25H8.75V12.75H13.3058L11.4558 14.6L12.5 15.6442ZM12.5016 21.5C11.1877 21.5 9.95268 21.2506 8.79655 20.752C7.6404 20.2533 6.63472 19.5765 5.7795 18.7217C4.92427 17.8669 4.24721 16.8616 3.74833 15.706C3.24944 14.5504 3 13.3156 3 12.0017C3 10.6877 3.24933 9.45268 3.748 8.29655C4.24667 7.1404 4.92342 6.13472 5.77825 5.2795C6.6331 4.42427 7.63834 3.74721 8.79398 3.24833C9.94959 2.74944 11.1844 2.5 12.4983 2.5C13.8122 2.5 15.0473 2.74933 16.2034 3.248C17.3596 3.74667 18.3652 4.42342 19.2205 5.27825C20.0757 6.1331 20.7527 7.13834 21.2516 8.29398C21.7505 9.44959 22 10.6844 22 11.9983C22 13.3122 21.7506 14.5473 21.252 15.7034C20.7533 16.8596 20.0765 17.8652 19.2217 18.7205C18.3669 19.5757 17.3616 20.2527 16.206 20.7516C15.0504 21.2505 13.8156 21.5 12.5016 21.5ZM12.5 20C14.7333 20 16.625 19.225 18.175 17.675C19.725 16.125 20.5 14.2333 20.5 12C20.5 9.76664 19.725 7.87498 18.175 6.32498C16.625 4.77498 14.7333 3.99998 12.5 3.99998C10.2666 3.99998 8.37498 4.77498 6.82498 6.32498C5.27498 7.87498 4.49998 9.76664 4.49998 12C4.49998 14.2333 5.27498 16.125 6.82498 17.675C8.37498 19.225 10.2666 20 12.5 20Z"
                        fill="#3A3A3A" />
                    </g>
                  </svg>
                {/if}

                {#if shortDescription.to}
                  <div class="flex items-center gap-2 rounded-xl">
                    {#if shortDescription.to.type === "image"}
                      <img
                        src={shortDescription.to.image}
                        alt={shortDescription.to.description}
                        class="h-full w-12" />
                    {:else if shortDescription.to.type === "text"}
                      <span
                        class="inline-flex aspect-square items-center justify-center rounded-full bg-neutral-800 px-3 text-lg text-white"
                        >{shortDescription.to.text}</span>
                    {/if}
                    <div class="text-center">
                      {shortDescription.to.description}
                    </div>
                  </div>
                {/if}
              </p>
            {/if}

            <div class="h-[1px] w-full bg-neutral-800" />

            {#if cartItem.type === "account" && cartItem.data.selected_skin}
              <span class="text-xs"
                >Selected Skin: {cartItem.data.selected_skin}</span>
            {/if}

            <div class="flex w-full flex-row flex-wrap justify-start gap-2">
              {#if cartItem.type === "account"}
                <Badge image={Rocket} imageAlt={"Instant Delivery"}>
                  Instant Delivery
                </Badge>
                {#if cartItem.subscription}
                  <Badge>
                    <Repeat class="mr-2 h-6 w-6" /> Subscription Discount: 60%</Badge>
                {/if}
              {/if}
              {#if $discountPercentage}
                <Badge>
                  Discount: {$discountPercentage * 100}%
                </Badge>
              {/if}
              {#if cartItem.price == 0}
                <Badge>FREE</Badge>
              {/if}
              {#if cartItem.type === "account" && cartItem.data.selected_skin}
                <Badge image={SkinIncluded} imageAlt={"Skin Included"}>
                  Skin Included
                </Badge>
              {/if}
              {#if cartItem.type === "boosting"}
                {@const selectedTypeDetails =
                  games?.[cartItem.data.game]?.[cartItem.data.type]}
                {#each typedEntries(selectedTypeDetails?.addons || {}) as [addonName, addon]}
                  {#if getAddonValue(cartItem, addonName)}
                    <Badge>
                      {addon.title}
                    </Badge>
                  {/if}
                {/each}
              {/if}
            </div>

            <button
              disabled={isLoadingCheckout}
              class=" inline-flex max-w-fit items-center gap-2 text-xs font-medium text-rose-600"
              on:click={() => removeCartItem(id)}
              ><Trash class="h-6 w-6" />Remove</button>
          </div>
        {/each}
      </aside>

      <div class="h-[1px] w-full bg-neutral-700" />

      <div class="flex justify-between py-2 text-white">
        <span class="font-semibold">Order Total: </span><span
          class="font-semibold"
          >{Object.values($cartItems)[0]?.currency}{total.toFixed(2)}</span>
      </div>

      <div class="h-[1px] w-full bg-neutral-700" />

      <div class="relative py-2 text-white">
        <form on:submit|preventDefault={() => startCheckout()} class="w-full">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <Mail class="h-6 w-6 text-zinc-300" />
          </div>
          {#if $user}
            <input
              type="email"
              class="w-full rounded-2xl border-none bg-neutral-900 p-4 pl-11 text-sm placeholder:text-zinc-500 disabled:cursor-not-allowed"
              placeholder="Enter your Email"
              value={$user.email}
              disabled={true}
              id="email_input" />
          {:else}
            <input
              type="email"
              class="w-full rounded-2xl border-none bg-neutral-900 p-4 pl-11 text-sm placeholder:text-zinc-500 disabled:cursor-not-allowed"
              placeholder="Enter your Email"
              bind:value={email}
              disabled={isLoadingCheckout}
              id="email_input" />
          {/if}
        </form>
      </div>

      <div class="h-[1px] w-full bg-neutral-700" />

      <p class="text-white">Choose Payment method</p>
      {#if checkoutErrors.length > 0}
        <Alert color="red">
          <svelte:fragment slot="icon">
            <svg
              aria-hidden="true"
              class="mr-2 h-5 w-5 text-red-700"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              ><path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd" /></svg>
          </svelte:fragment>
          <span class="sr-only">Info</span>
          <span class="font-medium"
            >Errors encountered while checking out:</span>
          <ul class="ml-8 mt-0 list-inside list-disc">
            {#each checkoutErrors as error}
              <li>{error}</li>
            {/each}
          </ul>
        </Alert>
      {/if}
      <div class="grid w-full gap-2">
        {#if !isLoadingCheckout}
          <div class="flex flex-col gap-2">
            <div>
              <input
                name="paymentMethod"
                type="radio"
                id="stripe"
                value="stripe"
                class="peer hidden"
                bind:group={selectedPaymentMethod} />
              <label
                for="stripe"
                class="flex cursor-pointer gap-2 rounded-2xl border-2 border-zinc-700 p-3 peer-checked:border-zinc-500 peer-checked:bg-neutral-900"
                ><Image src={Stripe} alt="Stripe" class="h-6 w-6" /> Stripe
                <StripePaymentMethods />
                <Image
                  src={selectedPaymentMethod === "stripe"
                    ? RadioChecked
                    : RadioUnchecked}
                  alt=""
                  class="ml-auto h-6 w-6" />
              </label>
            </div>
            <div>
              <input
                name="paymentMethod"
                type="radio"
                id="bitcoin"
                value="bitcoin"
                class="peer hidden"
                bind:group={selectedPaymentMethod} />
              <label
                for="bitcoin"
                class="flex cursor-pointer gap-2 rounded-2xl border-2 border-zinc-700 px-4 py-3 peer-checked:border-zinc-500 peer-checked:bg-neutral-900">
                <Image src={Bitcoin} alt="Bitcoin" class="h-6 w-6" /> Bitcoin
                <Image
                  src={selectedPaymentMethod === "bitcoin"
                    ? RadioChecked
                    : RadioUnchecked}
                  alt=""
                  class="ml-auto h-6 w-6" />
              </label>
            </div>
          </div>

          <button
            on:click={() => startCheckout()}
            class="enabled:hover:bg-gradient-colorful relative rounded-full bg-zinc-50 px-12 py-3 text-xl font-semibold text-black transition-colors duration-300 enabled:hover:animate-background enabled:hover:text-zinc-50 disabled:cursor-not-allowed disabled:opacity-50 2xl:text-xl">
            Buy Now
          </button>
        {:else}
          <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin" />
        {/if}
      </div>
    {:else}
      <p>Your cart is empty!</p>
    {/if}
  </div>
</Drawer>

<Modal title="Awaiting Payment!" bind:open={btcDialogOpen}>
  <div class="break-words">
    Send {btcAmount} to address {btcAddress} <br />
  </div>
  <div class="flex justify-center py-4">
    <QrCode value={btcQrcode} />
  </div>
  Or Click here{" "}
  <a class="font-bold" href={`bitcoin:${btcAddress}?amount=${btcAmount}`}>
    to use your offline wallet{" "}
  </a>
  <br />
  <b class="text-red-500">
    Note: This address is only available for 30 minutes.
  </b>
  <br />
  <div class="flex w-full items-center">
    <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin text-center" />
  </div>
</Modal>
<LoginDialog
  open={showLoginDialog}
  bind:email
  onOpenChange={() => (showLoginDialog = !showLoginDialog)} />
